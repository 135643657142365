import React from 'react';
import { Box, Divider, Typography, Tooltip, IconButton, Modal } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';

const HealthChoices = ({ healthOptList }) => {
    const [openHealthModal, setOpenHealthModal] = React.useState(false);

    const handleOpenHealthOptsModal = () => setOpenHealthModal(true);
    const handleCloseHealthOptsModal = () => setOpenHealthModal(false);

    return (
        <span>
            <Tooltip title="Health Choices Information">
                <IconButton onClick={handleOpenHealthOptsModal}>
                    <InfoIcon />
                </IconButton>
            </Tooltip>
            <Modal
                open={openHealthModal}
                onClose={handleCloseHealthOptsModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseHealthOptsModal}
                        sx={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>

                    {healthOptList.map(opt => (
                        <div key={opt.id}>
                            <Typography variant="body1" sx={{ marginBottom: '.5rem', color: '#464646', fontSize: 15 }}>
                                <b>{opt.name}</b>: {opt.description}
                            </Typography>
                        </div>
                    ))}
                    <Typography variant="body1" sx={{ marginTop: '.5rem', color: '#464646', fontSize: 15 }}>
                        <i>
                            <u>*Disclaimer for all</u>: Any claims made externally must be reviewed and approved by the local Regulatory team
                        </i>
                    </Typography>
                </Box>
            </Modal>
        </span>
    );
};

export default HealthChoices;
