import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import GradingIcon from '@mui/icons-material/Grading';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import { format } from 'date-fns'
import { unwrapResult } from '@reduxjs/toolkit'
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import ConfirmDialog, { confirmDialog } from '../../shared/ConfirmDialog';
import { getUser } from '../../../store/userSlice'
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import {
  fetchUnpublishedConcepts,
  fetchDupeConcepts,
  getUnpublishedConcepts,
  publishConcept,
  rejectConcept,

} from '../../../store/conceptsSlice'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.38)',
    color: theme.palette.common.white,
    fontSize: 14,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function ReviewDuplicatesDialog(props) {
  const dispatch = useDispatch();
  const { onClose, selectedvalue, open } = props;

  const [conceptList, setConceptList] = React.useState([]);
  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    console.log(open);
    if (open) {
      fetch('api/conceptitems/getduplicates/' + selectedvalue)
        .then((data) => data.json())
        .then((data) => {
          setConceptList(data);
          console.log("Dupes", JSON.stringify(conceptList))
        })
    }
  }, [open]);

  return (
    <Dialog onClose={handleClose} open={open} selectedvalue={selectedvalue} fullWidth={true} maxWidth="lg">
      <DialogTitle>Review Possible Concept Duplicates</DialogTitle>
      <DialogContent>
   
      <TableContainer component={Paper} sx={{ margin: "0 auto", maxWidth: 1250 }}>
        <Table sx={{}} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ width: 10 }}></StyledTableCell>
              <StyledTableCell sx={{ width: 350 }}>Concept Name</StyledTableCell>
              <StyledTableCell>Concept Description</StyledTableCell>            
              <StyledTableCell align="center" sx={{ width: 50 }}>Review</StyledTableCell>              
            </TableRow>
          </TableHead>
          <TableBody>
            {conceptList.map((row) => (
              <StyledTableRow key={row.objectID}>

                <StyledTableCell align="center">
         
                  {(() => {
                    if (row.objectID == selectedvalue) {
                      return (
                        <>
                          <Tooltip title="Concept under review" arrow>
                            <ReportProblemIcon                              
                              color="warning"
                              sx={{ cursor: 'pointer', width: '0.8em', verticalAlign: 'middle', }} />
                          </Tooltip>
                        </>
                      )
                    }
                  })()}
                </StyledTableCell>
                <StyledTableCell>{row.conceptName}</StyledTableCell>
                <StyledTableCell>{row.description}</StyledTableCell>
                <StyledTableCell align="center"><Link href={`/api/presentations/previewpresentationconcept/export/${row.objectID}`}><VisibilityIcon sx={{ cursor: 'pointer', width: '0.8em', color: '#a0c800' }} /></Link></StyledTableCell>

              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function Status() {
  const [openDupesDialog, setOpenDupesDialog] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(0);

  const handleDupesDialogOpen = (value) => {
    setSelectedValue(value);
    setOpenDupesDialog(true);
  };

  const handleDupesDialogClose = () => {
    setOpenDupesDialog(false);
  };

  const dispatch = useDispatch();
  const rows = useSelector(getUnpublishedConcepts);
  const [snackbar, setSnackbar] = React.useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const user = useSelector(getUser);

  useEffect(() => {
    dispatch(fetchUnpublishedConcepts())
  }, [])

  const handlePublishConcept = (id) => {
    dispatch(publishConcept(id))
      .then(unwrapResult).then(originalPromiseResult => {
        dispatch(fetchUnpublishedConcepts())
        setSnackbar({ children: 'Concept was successfully published', severity: 'success' })
      })
      .catch(rejectedValueOrSerializedError => {
        setSnackbar({ children: `An error has occured while publishing concept. Check log for details.`, severity: 'error' });
      })
  }

  const handleRejectConcept = (id) => {
    console.log('handleRejectConcept')
    dispatch(rejectConcept(id))
      .then(unwrapResult).then(originalPromiseResult => {
        dispatch(fetchUnpublishedConcepts())
        setSnackbar({ children: 'Concept successfully deleted', severity: 'success' });
      })
      .catch(rejectedValueOrSerializedError => {
        setSnackbar({ children: `An error has occured deleting concept. Check log for details.`, severity: 'error' });
      })
  }

  return (
    <>
      <ReviewDuplicatesDialog
        selectedvalue={selectedValue}
        open={openDupesDialog}
        onClose={handleDupesDialogClose}
      />
      <ConfirmDialog />
      <TableContainer component={Paper} sx={{ margin: "0 auto", maxWidth: 1250 }}>
        <Table sx={{}} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ width: 10 }}></StyledTableCell>
              <StyledTableCell sx={{ width: 50 }}>Country/Region</StyledTableCell>
              <StyledTableCell>Concept Name</StyledTableCell>
              <StyledTableCell sx={{ width: 120 }}>Concept Date</StyledTableCell>
              <StyledTableCell sx={{ width: 50 }}>Added On</StyledTableCell>
              <StyledTableCell sx={{ width: 300 }}>Added By</StyledTableCell>
              <StyledTableCell align="center" sx={{ width: 50 }}>Review</StyledTableCell>
              <StyledTableCell align="center" sx={{ width: 50 }}>Edit</StyledTableCell>
              <StyledTableCell align="center" sx={{ width: 50 }}>Publish</StyledTableCell>
              <StyledTableCell align="center" sx={{ width: 50 }}>Reject</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row.id}>

                <StyledTableCell align="center">
                  {(() => {
                    if (row.possibleDuplicate) {
                      return (
                        <>
                          <Tooltip title="Review possible duplication" arrow>
                            <ReportProblemIcon
                              onClick={() =>
                                handleDupesDialogOpen(row.id)
                              }
                              color="warning"
                              sx={{ cursor: 'pointer', width: '0.8em', verticalAlign: 'middle', }} />
                          </Tooltip>
                        </>
                      )
                    }
                  })()}
                </StyledTableCell>

                <StyledTableCell>{row.regionCountry}</StyledTableCell>
                <StyledTableCell>{row.conceptName}</StyledTableCell>
                <StyledTableCell>{format(new Date(row.creationDate), 'MMMM yyyy')}</StyledTableCell>
                <StyledTableCell>{format(new Date(row.recordAddedDate), 'MM/dd/yyyy')}</StyledTableCell>
                <StyledTableCell>{row.recordAddedBy}</StyledTableCell>
                <StyledTableCell align="center"><Link href={`/api/presentations/previewpresentationconcept/export/${row.id}`}><VisibilityIcon sx={{ cursor: 'pointer', width: '0.8em', color: '#a0c800' }} /></Link></StyledTableCell>
                <StyledTableCell align="center"><Link href={`/admin/edit-concept/${row.id}`}><ModeEditOutlineIcon sx={{ cursor: 'pointer', width: '0.8em', color: '#a0c800' }} /></Link></StyledTableCell>

                {(() => {

                  if (user.accessTier === 2) {
                    return (
                      <>
                        <StyledTableCell align="center"><GradingIcon
                          onClick={() =>
                            handlePublishConcept(row.id)
                          } sx={{ cursor: 'pointer', width: '0.8em', color: '#a0c800' }} /></StyledTableCell>
                        <StyledTableCell align="center"><HighlightOffIcon
                          onClick={() =>
                            confirmDialog("Are you sure you want to delete this record?",
                              handleRejectConcept.bind(
                                this,
                                row.id,)
                            )
                          }
                          color="warning"
                          sx={{ cursor: 'pointer', width: '0.8em' }} /></StyledTableCell>
                      </>
                    )
                  } else {
                    return (
                      <>
                        <StyledTableCell align="center"><GradingIcon sx={{ cursor: 'default', width: '0.8em', color: '#D3D3D3' }} /></StyledTableCell>
                        <StyledTableCell align="center"><HighlightOffIcon sx={{ cursor: 'default', width: '0.8em', color: '#D3D3D3' }} /></StyledTableCell>
                      </>
                    )
                  }
                })()}



              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!!snackbar && (
        <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={1600}>
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </>
  );
}
