import React, { useEffect, useState } from "react";
import "./ManageCulinaryConcepts.css"
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container'
import {
  Divider, Badge, Button, TextField, FormHelperText,
  Radio, RadioGroup, FormLabel, FormControl, FormControlLabel, MenuItem
} from '@mui/material';
import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import uploadFileToBlob, { isStorageConfigured } from '../../components/admin/upload/AzureBlobStorage'
import { format } from 'date-fns'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { useSelector, useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { updateConcept } from '../../store/conceptsSlice'
import { useHistory, useParams } from 'react-router-dom'
import { data } from "jquery";
import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import HealthChoices from "../../components/admin/culinary-concepts/HealthOptionsModal";

export default function EditCulinaryConcepts() {
  const { conceptId } = useParams()
  const [concept, setConcept] = useState([]);
  const containerName = `concept-locker`;
  const storageAccountName = `flavoriqblobstorage`;
  const fallbackSrc = "/images/default-ppt.png";
  const [image, setImage] = useState({ preview: fallbackSrc, raw: "" });
  const [originalImageName, setOriginalImageName] = useState(null);

  useEffect(() => {
    fetch('api/conceptitems/GetConceptItem/' + conceptId)
      .then((data) => data.json())
      .then((data) => {
      //  console.log("DATA:: " + JSON.stringify(data))
        if (data.blobImageName) {
          setOriginalImageName(data.blobImageName);
          let imagePath = `https://${storageAccountName}.blob.core.windows.net/${containerName}/images/concepts/`
            + format(new Date(data.creationDate), 'yyyy') + '/'
            + format(new Date(data.creationDate), 'MMM').toLowerCase() + '/' + data.blobImageName;
          data.blobImageName = imagePath;
        } else {
          data.blobImageName = fallbackSrc;
        }
        setConcept(data)
      })
  }, [])

  return (
    <>
      <Stack spacing={2} direction="row" sx={{ margin: "20px auto 0", maxWidth: 1250 }}>

        <Button
          variant="contained"
          color="secondary"
          size="large"

          href="/admin/"
        >
          ADMIN DASHBOARD
        </Button>

      </Stack>
      <ConceptsFormik concept={{
        id: concept.id,
        conceptName: concept.conceptName,
        mealPart: concept.mealPart,
        conceptType: concept.type,
        description: concept.description,
        creationDate: concept.creationDate,
        chef: concept.chef,
        regionCountry: concept.regionCountry,
        regionalInsight: concept.regionalInsight,
        brand: concept.brand ? concept.brand : 999,
        brandOtherText: concept.brandOtherText,
        healthOptions: concept.healthOptions,
        productCat1: concept.productCat1,
        productCat1Description: concept.productCat1Description,
        productCat2: concept.productCat2,
        productCat2Description: concept.productCat2Description,
        productCat3: concept.productCat3,
        productCat3Description: concept.productCat3Description,
        productCat4: concept.productCat4,
        productCat4Description: concept.productCat4Description,
        photoURL: concept.blobImageName,
        originalImageName: originalImageName,
        publishedDate: concept.publishedDate,
      }} />
    </>
  )
}

const ConceptsFormik = (props) => {
  const fallbackSrc = "/images/default-ppt.png";
  const [chefList, setChefList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [mealpartList, setMealPartList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [healthOptList, setHealthOptList] = useState([]);
  const [productCatList, setProductCatList] = useState([]);
  const FILE_SIZE_MAX = 500000
  const [image, setImage] = useState({ preview: fallbackSrc, raw: "" });
  const [savingConcept, setSavingConcept] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    fetch('api/mealparts')
      .then((data) => data.json())
      .then((data) => setMealPartList(data))

    fetch('api/chefs')
      .then((data) => data.json())
      .then((data) => setChefList(data), [])

    fetch('api/brands')
      .then((data) => data.json())
      .then((data) => setBrandList(data))

    fetch('api/countries')
      .then((data) => data.json())
      .then((data) => setRegionList(data))

    fetch('api/healthoptions')
        .then((data) => data.json())
        .then((data) => setHealthOptList(data))

    fetch('api/productcategories')
      .then((data) => data.json())
      .then((data) => setProductCatList(data))
  }, [])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: props.concept.id,
      conceptName: props.concept.conceptName,
      mealPart: props.concept.mealPart,
      conceptType: props.concept.conceptType,
      description: props.concept.description,
      creationDate: props.concept.creationDate,
      chef: props.concept.chef,
      regionCountry: props.concept.regionCountry,
      regionalInsight: props.concept.regionalInsight,
      brand: props.concept.brand,
      brandOtherText: props.concept.brandOtherText,
      healthOptions: props.concept.healthOptions?.map(option => option.id) ?? [],
      productCat1: props.concept.productCat1,
      productCat1Description: props.concept.productCat1Description,
      productCat2: props.concept.productCat2,
      productCat2Description: props.concept.productCat2Description,
      productCat3: props.concept.productCat3,
      productCat3Description: props.concept.productCat3Description,
      productCat4: props.concept.productCat4,
      productCat4Description: props.concept.productCat4Description,
      photoURL: props.concept.photoURL,
      photo: null,
      publishedDate: props.concept.publishedDate,
    },
    validationSchema: Yup.object({
      conceptName: Yup.string()
        .required('Concept name is required'),
      mealPart: Yup.string()
        .required('Meal part is required'),
      description: Yup.string()
        .required('Description is required'),
      chef: Yup.string()
        .required('Chef is required'),
      regionCountry: Yup.string()
        .required('Country or region is required'),
      brand: Yup.string()
        .required('Brand is required'),
      brandOtherText: Yup.string().ensure().when('brand', {
        is: '999',
        then: Yup.string().required('Brand is required')
      }),
      productCat1: Yup.number()
        .required('At least one product category is required'),
      productCat1Description: Yup.string().when('productCat1', {
        is: (productCat1) => productCat1,
        then: Yup.string().required('Enter a description for this product'),
        otherwise: Yup.string(),
      }),
      productCat2Description: Yup.string().when('productCat2', {
        is: (productCat2) => productCat2,
        then: Yup.string().required('Enter a description for this product'),
        otherwise: Yup.string(),
      }),
      productCat3Description: Yup.string().when('productCat3', {
        is: (productCat3) => productCat3,
        then: Yup.string().required('Enter a description for this product'),
        otherwise: Yup.string(),
      }),
      productCat4Description: Yup.string().when('productCat4', {
        is: (productCat4) => productCat4,
        then: Yup.string().required('Enter a description for this product'),
        otherwise: Yup.string().nullable(true),
      }),
      // photo: Yup.mixed()
      //    .nullable(true)
      //    .notRequired()
      //    .test("FILE_SIZE_MAX", "Uploaded file is too big. Maximum 5 MB.",
      //      value => value && (value.size <= FILE_SIZE_MAX)),
    }),
    onSubmit: values => {
      //if not default image push image to azure
      if (values.photo) {
        onImageUpload(image.raw)
      } 
      saveConcept(values)
    },
  });

  function transFormToNumber(value) {
    return value === 0 ? null : Number(value);
  }

  function saveConcept(formData) {
    if (savingConcept)
      return

    var fileName = null
    if (image.raw) {
      fileName = image.raw.name
      
    } else if (props.concept.originalImageName) {
      fileName = props.concept.originalImageName
    }

    const healthOptions = healthOptList.filter(opt => formData.healthOptions.some(s => s === opt.id));

    var data = {
      id: formData.id,
      ConceptName: formData.conceptName,
      MealPart: formData.mealPart,
      Brand: formData.brand,
      BrandOtherText: formData.brandOtherText,
      Chef: formData.chef,
      Description: formData.description,
      Type: formData.conceptType,
      CreationDate: new Date(formData.creationDate).toISOString(),
      RegionCountry: formData.regionCountry,
      RegionalInsight: formData.regionalInsight,
      BlobImageName: fileName,
      healthOptions: healthOptions,
      ProductCat1: transFormToNumber(formData.productCat1),
      ProductCat1Description: formData.productCat1Description,
      ProductCat2: transFormToNumber(formData.productCat2),
      ProductCat2Description: formData.productCat2Description,
      ProductCat3: transFormToNumber(formData.productCat3),
      ProductCat3Description: formData.productCat3Description,
      ProductCat4: transFormToNumber(formData.productCat4),
      ProductCat4Description: formData.productCat4Description,
      PublishedDate: props.concept.publishedDate
    };

     console.log("save data:::" + JSON.stringify(data))
    setSavingConcept(true);

    dispatch(updateConcept(data))
      .then(unwrapResult).then(originalPromiseResult => {
        setSavingConcept(false);
        setSnackbar({ children: 'Concept saved successfully', severity: 'success' });
        
      }).then(() => {
        if(props.concept.publishedDate) {
          navigate('/concepts')
        } else {
          navigate('/admin')
        }
      })

      .catch(rejectedValueOrSerializedError => {
        console.log(rejectedValueOrSerializedError);
        setSavingConcept(false);
        setSnackbar({ children: 'Failed to save concept', severity: 'error' });
      });
  }

  const onImageUpload = async (file) => {
    // *** UPLOAD TO AZURE STORAGE ***  
    let imagePath = '/images/concepts/'
      + format(new Date(formik.values.creationDate), 'yyyy') + '/'
      + format(new Date(formik.values.creationDate), 'MMM').toLowerCase() + '/';
    await uploadFileToBlob(imagePath, file);
  }

  const onKeyDown = (e) => {
    e.preventDefault();
 };

  return (
    <Container
      sx={{
        maxWidth: "1340px",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 2,
        mb: 2,
      }} fixed>

      <Box sx={{ my: 4 }}>
        <Typography variant="h4" align="center">
          Edit Culinary Concept
        </Typography>
      </Box>

      <Box className=""
        sx={{
          width: '70%'
        }}>

        <form onSubmit={formik.handleSubmit}>

          <FormControl sx={{ mt: 2 }} fullWidth>
            <Badge sx={{ textAlign: 'right', fontSize: 12, display: 'inline-block', fontWeight: 500, }}>
              {formik.values.conceptName ? formik.values.conceptName.length : 0} / 150 characters
            </Badge>
            <TextField id="conceptName" label="Concept Name*" variant="outlined"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.conceptName ? formik.values.conceptName : ''}
              fullWidth
              inputProps={{
                maxLength: 150,
              }}
            />
          </FormControl>
          <FormHelperText className="error-message">
            {formik.touched.conceptName && formik.errors.conceptName ?
              formik.errors.conceptName
              : ' '}
          </FormHelperText>

          <TextField
            id="mealPart"
            select
            label="Meal Part*"
            value={formik.values.mealPart ? formik.values.mealPart : ''}
            onChange={selectedOption => {
              formik.handleChange("mealPart")(selectedOption);
            }}
            sx={{ minWidth: 220, mt: 2, }}
            variant="outlined"
          >
            {mealpartList.map(option => (
              <MenuItem key={option.id} value={option.id}>
                {option.mealPartName}
              </MenuItem>
            ))}
          </TextField>
          <FormHelperText className="error-message">
            {formik.touched.mealPart && formik.errors.mealPart ?
              formik.errors.mealPart
              : ' '}
          </FormHelperText>

          <FormControl sx={{ mt: 2 }}>
            <FormLabel id="type-group" sx={{ color: '#464646', fontSize: 17, fontWeight: 500 }}>Type:*</FormLabel>
            <RadioGroup
              row
              aria-labelledby="type-group"
              name="conceptType"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <FormControlLabel value="Commercial" control={<Radio />} label="Commercial" checked={formik.values.conceptType === 'Commercial'} />
              <FormControlLabel value="Non-Commercial" control={<Radio />} label="Non-Commercial" checked={formik.values.conceptType === 'Non-Commercial'} />
            </RadioGroup>
          </FormControl>


          <FormControl fullWidth>
            <Badge sx={{ textAlign: 'right', fontSize: 12, display: 'inline-block', fontWeight: 500, }}>
              {formik.values.description ? formik.values.description.length : 0} / 275 characters
            </Badge>
            <TextField
              id="description" label="Concept Description*" variant="outlined"
              multiline
              rows={4}
              value={formik.values.description ? formik.values.description : ''}
              fullWidth
              inputProps={{
                maxLength: 275,
              }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </FormControl>
          <FormHelperText className="error-message">
            {formik.touched.description && formik.errors.description ?
              formik.errors.description
              : ' '}
          </FormHelperText>

          <Box sx={{ display: 'block', width: '100%' }}>
            <FormControl sx={{ mb: 2, mt: 4, }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  views={['year', 'month']}
                  label="Creation Date*"
                  openTo='month'
                  minDate={dayjs('2023-01-15')}
                  maxDate={dayjs('2023-12-15')}
                  value={dayjs(formik.values.creationDate)}
                  disabled={true}
                  onChange={(value) => {
                    formik.setFieldValue('creationDate', Date.parse(value));
                  }}
                  renderInput={(params) => <TextField onKeyDown={onKeyDown} {...params} helperText={null} name="creationDate" />}
                />
              </LocalizationProvider>
            </FormControl>
          </Box>

          <TextField
            id="chef"
            select
            label="Chef*"
            value={formik.values.chef ? formik.values.chef : ''}
            onChange={selectedOption => {
              formik.handleChange("chef")(selectedOption);
            }}
            sx={{ minWidth: 220, mt: 3, }}
            variant="outlined"
          >
            {chefList.map(option => (
              <MenuItem key={option.id} value={option.id}>
                {option.chefName}
              </MenuItem>
            ))}
          </TextField>
          <FormHelperText className="error-message">
            {formik.touched.chef && formik.errors.chef ?
              formik.errors.chef
              : ' '}
          </FormHelperText>

          <TextField
            id="regionCountry"
            select
            label="Country or Region*"
            value={formik.values.regionCountry ? formik.values.regionCountry : ''}
            onChange={selectedOption => {
              formik.handleChange("regionCountry")(selectedOption);
            }}
            sx={{ minWidth: 220, mt: 2, }}
            variant="outlined">
            {regionList.map(option => (
              <MenuItem key={option.countryCode} value={option.countryCode}>
                {option.countryLongName}
              </MenuItem>
            ))}
          </TextField>
          <FormHelperText className="error-message">
            {formik.touched.regionCountry && formik.errors.regionCountry ?
              formik.errors.regionCountry
              : ' '}
          </FormHelperText>

          <FormControl sx={{ mt: 0, mb: 1 }} fullWidth>
            <Badge sx={{ textAlign: 'right', fontSize: 12, display: 'inline-block', fontWeight: 500, }}>
              {formik.values.regionalInsight ? formik.values.regionalInsight.length : 0} / 275 characters
            </Badge>
            <TextField
              id="regionalInsight" label="Regional Insight" variant="outlined"
              multiline
              rows={4}
              value={formik.values.regionalInsight ? formik.values.regionalInsight : ''}
              fullWidth
              inputProps={{
                maxLength: 275,
              }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </FormControl>
         

          <Box sx={{ display: 'flex', width: '100%' }}>
            <TextField
              id="brand"
              select
              label="Associated Brand*"
              value={formik.values.brand ? formik.values.brand : 999}
              onChange={selectedOption => {
                formik.handleChange("brand")(selectedOption);
              }}
              sx={{ minWidth: 220, mt: 3, }}
              variant="outlined"
            >
              <MenuItem key="999" value="999" sx={{ color: '#a0c800', fontWeight: 600, fontSize: 18 }}>
                Other Brand
              </MenuItem>
              {brandList.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.brandName}
                </MenuItem>
              ))}
            </TextField>
            {formik.values.brand === '999' || formik.values.brandOtherText ?
              
                <FormControl sx={{ mt: 3, mx: 2, mb: -1 }} >
                  <TextField id="brandOtherText" label='Enter "Other" Brand Name' variant="outlined"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.brandOtherText ? formik.values.brandOtherText : ''}
                    sx={{ minWidth: 570 }}
                    inputProps={{
                      maxLength: 50,
                    }}
                  />
                </FormControl>
             
              : null
            }
          </Box>
          <FormHelperText className="error-message">
            {formik.touched.brand && formik.errors.brand ?
              formik.errors.brand
              : ' '}
            {formik.touched.brandOtherText && formik.errors.brandOtherText ?
              formik.errors.brandOtherText
              : ' '}
          </FormHelperText>

          {/*<FormControl sx={{ display: 'flex', width: '100%' }}>*/}

          <Box sx={{mt: 5}}>
            <Divider textAlign="left">Health Choices:</Divider>
            <Typography variant="p" sx={{color: '#464646', fontSize: 15}}>
              Select the featured health attribute for this concept.
              <br/><em>*Multiple health choices can be applied</em>
            </Typography>
            <HealthChoices healthOptList={healthOptList}/>
          </Box>

          <FormControl sx={{mt: 3, width: '100%'}}>
            <InputLabel id="health-choices-select-label">Health Choices</InputLabel>
            <Select
                id="health-choices"
                labelId="health-choices-select-label"
                multiple
                label="Health Choices"
                value={formik.values.healthOptions ?? []  }
                onChange={selectedOption => {
                  console.log(selectedOption)
                  formik.handleChange("healthOptions")(selectedOption);
                }}
                variant="outlined"
            >
              {healthOptList.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box sx={{ mt: 5 }}>
            <Divider textAlign="left">Featured Products:</Divider>
            <Typography variant="p" sx={{ color: '#464646', fontSize: 15 }}>
              Select up to 4 product categories and enter associated product descriptions. <br /><em>*Descriptions are limited to 140 characters each.</em>
            </Typography>
          </Box>

          <Badge sx={{ mt: 2, width: '99%', textAlign: 'right', fontSize: 12, display: 'inline-block', fontWeight: 500, }}>
            {formik.values.productCat1Description ? formik.values.productCat1Description.length : 0} / 140 characters
          </Badge>
          <Box sx={{ display: 'flex', width: '100%' }}>
            <TextField
              id="productCat1"
              select
              label="1st Featured Product *"
              value={formik.values.productCat1 ? formik.values.productCat1 : 0}
              onChange={selectedOption => {
                formik.handleChange("productCat1")(selectedOption);
              }}
              sx={{ width: 250 }}
              variant="outlined"
            >
              {productCatList.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.categoryName}
                </MenuItem>
              ))}
            </TextField>

            <FormControl sx={{ mx: 2, }} >
              <TextField id="productCat1Description" label='Description' variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.productCat1Description ? formik.values.productCat1Description : ''}
                sx={{ width: 545 }}
                inputProps={{
                  maxLength: 140,
                }}
              />
            </FormControl>
          </Box>
          <FormHelperText className="error-message">
            {formik.touched.productCat1Description && formik.errors.productCat1Description ?
              formik.errors.productCat1Description
              : ' '}
            {formik.touched.productCat1 && formik.errors.productCat1 ?
              formik.errors.productCat1
              : ' '}
          </FormHelperText>

          <Badge sx={{ mt: 2, width: '99%', textAlign: 'right', fontSize: 12, display: 'inline-block', fontWeight: 500, }}>
            {formik.values.productCat2Description ? formik.values.productCat2Description.length : 0} / 140 characters
          </Badge>
          <Box sx={{ display: 'flex', width: '100%' }}>
            <TextField
              id="productCat2"
              select
              label="2nd Featured Product"
              value={formik.values.productCat2 ? formik.values.productCat2 : ''}
              onChange={selectedOption => {
                formik.handleChange("productCat2")(selectedOption);
              }}
              sx={{ width: 250 }}
              variant="outlined"
            >
              {productCatList.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.categoryName}
                </MenuItem>
              ))}
            </TextField>
            <FormControl sx={{ mx: 2, }} >
              <TextField id="productCat2Description" label='Description' variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.productCat2Description ? formik.values.productCat2Description : ''}
                sx={{ width: 545 }}
                inputProps={{
                  maxLength: 140,
                }}
              />
            </FormControl>
          </Box>
          <FormHelperText className="error-message">
            {formik.touched.productCat2Description && formik.errors.productCat2Description ?
              formik.errors.productCat2Description
              : ' '}
          </FormHelperText>

          <Badge sx={{ mt: 2, width: '99%', textAlign: 'right', fontSize: 12, display: 'inline-block', fontWeight: 500, }}>
            {formik.values.productCat3Description ? formik.values.productCat3Description.length : 0} / 140 characters
          </Badge>
          <Box sx={{ display: 'flex', width: '100%' }}>
            <TextField
              id="productCat3"
              select
              label="3rd Featured Product"
              value={formik.values.productCat3 ? formik.values.productCat3 : ''}
              onChange={selectedOption => {
                formik.handleChange("productCat3")(selectedOption);
              }}
              sx={{ width: 250 }}
              variant="outlined"
            >
              {productCatList.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.categoryName}
                </MenuItem>
              ))}
            </TextField>
            <FormControl sx={{ mx: 2, }} >
              <TextField id="productCat3Description" label='Description' variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.productCat3Description ? formik.values.productCat3Description : ''}
                sx={{ width: 545 }}
                inputProps={{
                  maxLength: 140,
                }}
              />
            </FormControl>
          </Box>
          <FormHelperText className="error-message">
            {formik.touched.productCat3Description && formik.errors.productCat3Description ?
              formik.errors.productCat3Description
              : ' '}
          </FormHelperText>

          <Badge sx={{ mt: 2, width: '99%', textAlign: 'right', fontSize: 12, display: 'inline-block', fontWeight: 500, }}>
            {formik.values.productCat4Description ? formik.values.productCat4Description.length : 0} / 140 characters
          </Badge>
          <Box sx={{ display: 'flex', width: '100%' }}>
            <TextField
              id="productCat4"
              select
              label="4th Featured Product"
              value={formik.values.productCat4 ? formik.values.productCat4 : ''}
              onChange={selectedOption => {
                formik.handleChange("productCat4")(selectedOption);
              }}
              sx={{ width: 250 }}
              variant="outlined"
            >
              {productCatList.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.categoryName}
                </MenuItem>
              ))}
            </TextField>
            <FormControl sx={{ mx: 2 }} >
              <TextField id="productCat4Description" label='Description' variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.productCat4Description ? formik.values.productCat4Description : ''}
                sx={{ width: 545 }}
                inputProps={{
                  maxLength: 140,
                }}
              />
            </FormControl>
          </Box>
          <FormHelperText className="error-message">
            {formik.touched.productCat4Description && formik.errors.productCat4Description ?
              formik.errors.productCat4Description
              : ' '}
          </FormHelperText>


          <Divider textAlign="left" sx={{ my: 3, mt: 5 }}>Upload Concept Photo</Divider>
          <Typography variant="p" sx={{ color: '#464646', fontSize: 15 }}>
            For best results, concept photo needs to be exactly 1056px wide by 650px tall <em>OR</em> 528px wide by 325px tall.
          </Typography>

          <FormHelperText sx={{ textAlign: 'right', mr: 2, color: '#ff9300' }}>
            {formik.touched.photo && formik.errors.photo ?
              formik.errors.photo
              : 'Maximum 5 MB'}
          </FormHelperText>
          <Box sx={{ display: 'flex', width: '100%' }}>
            <FormControl sx={{ mt: 2, mx: 2, mb: 2, display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', width: '100%' }} >

              <Button variant="contained" component="label" size="large" sx={{ color: '#fff', flex: '1 100%' }} >
                Select File
                <input hidden accept="image/png, image/jpeg, image/jpg" type="file" id="photo" name="photo"
                  onChange={(event) => {
                    setImage({
                      preview: URL.createObjectURL(event.currentTarget.files[0]),
                      raw: event.currentTarget.files[0]
                    });
                    formik.setFieldValue("photo", event.currentTarget.files[0]);
                    formik.setFieldValue("photoURL", URL.createObjectURL(event.currentTarget.files[0]));
                    formik.handleChange(event)
                  }}
                />
              </Button>

              <FormLabel id="upload-group" sx={{ fontSize: 17, fontWeight: 500, width: '100%' }}>
                <img src={formik.values.photoURL} className="preview-image" />
              </FormLabel>

            </FormControl>
          </Box>


          <Box sx={{ textAlign: 'center', width: '100%', mt: 6 }}>
            <FormHelperText className="error-message" sx={{ textAlign: 'center' }}>
              {(formik.touched.conceptName && formik.errors.conceptName)
                || (formik.touched.mealPart && formik.errors.mealPart)
                || (formik.touched.description && formik.errors.description)
                || (formik.touched.chef && formik.errors.chef)
                || (formik.touched.regionCountry && formik.errors.regionCountry)              
                || (formik.touched.brandOtherText && formik.errors.brandOtherText)
                || (formik.touched.productCat1Description && formik.errors.productCat1Description)
                || (formik.touched.productCat1 && formik.errors.productCat1)
                || (formik.touched.productCat2Description && formik.errors.productCat2Description)
                || (formik.touched.productCat3Description && formik.errors.productCat3Description)
                || (formik.touched.productCat4Description && formik.errors.productCat4Description)
                ? 'Please enter all required fields.'
                : ' '}
            </FormHelperText>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              type="submit"
              sx={{
                mt: 2,
                mb: 6,
                px: 6,

              }}
              disabled={savingConcept}
            >
              SAVE CONCEPT
            </Button>
          </Box>
          {!!snackbar && (
        <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={1600}>
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
        </form>

      </Box>

    </Container>
  );


}



