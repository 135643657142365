import React, { useEffect, useState } from "react";
import styled from '@emotion/styled';
import "./ManageConceptLockerFilters.css"
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Status from '../../components/admin/culinary-concepts/Status'
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { getUser, getUserStatus, getUserError, fetchAuthUser } from '../../store/userSlice'
import { useSelector, useDispatch } from 'react-redux'

export default function ManageConceptLockerFilters() {
  const user = useSelector(getUser);

  return (

    <main>
      <Container maxWidth="xl" sx={{ paddingBottom: 25 }}>
        <Box sx={{ my: 4 }}>
          <Typography variant="h4" align="center">
            Manage Culinary Concepts
          </Typography>
        </Box>

        <Stack spacing={2} direction="row" sx={{ margin: "0 auto", maxWidth: 1250 }}>

                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  
                  href="/admin/create-concept"
                >
                  NEW CONCEPT
                </Button>


                {user.accessTier === 2 ? 
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"                 
                  href="/admin/manage-filters"
                >
                  MANAGE FILTERS
                </Button>
                : ''}
              </Stack>

        <Box sx={{ flexGrow: 1 }}>
          <Grid container sx={{ justifyContent: 'center' }}>
            
            <Grid item xs={12}>
              <Typography gutterBottom variant="h6" align="center">Concepts Awaiting Approval</Typography>
              <Status />
            </Grid>

          </Grid>
        </Box>

      </Container>
    </main>

  );


}



