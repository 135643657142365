import React, { useEffect, useState } from "react";
import "./ManageCulinaryConcepts.css"
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container'
import CreateConcept from "../../components/admin/culinary-concepts/CreateConcept";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

export default function CreateCulinaryConcepts() {

    return (

        <>
            <Stack spacing={2} direction="row" sx={{ margin: "20px auto 0", maxWidth: 1250 }}>

                <Button
                    variant="contained"
                    color="secondary"
                    size="large"

                    href="/admin"
                >
                    ADMIN DASHBOARD
                </Button>

            </Stack>
            <Container
                sx={{
                    maxWidth: "1340px",
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 0,
                    mb: 2,
                }} fixed>

                <Box sx={{ my: 4 }}>
                    <Typography variant="h4" align="center">
                        Create Culinary Concept
                    </Typography>
                </Box>



                <Box className=""
                    sx={{
                        width: '70%'
                    }}>
                    <CreateConcept />
                </Box>

            </Container>
        </>
    );


}



