import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
    unpublishedConcepts: [],
    status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
}

export const fetchUnpublishedConcepts = createAsyncThunk('conceptItems/fetchUnpublishedConcepts', async () => {
    try {
        const response = await axios.get('api/conceptitems/getpending/getpending')
        return response.data;
    } catch (err) {
        return err.message;
    }
})

export const rejectConcept = createAsyncThunk('concepts/rejectConcept'
    , async (id, { rejectWithValue }) => {
        try {
            const headers = {
                "Accept": "application/json",
                "Content-Type": "application/json",
            };
            const apiUrl = 'api/conceptitems/DeleteConceptItem/' + id
            const response = await axios.delete(apiUrl, id,
                { headers })
            console.log('deleting')
            return response.data;
        } catch (err) {
            console.log("err: " + JSON.stringify(err.message))
            return rejectWithValue([], err);
        }
    })

export const createConcept = createAsyncThunk('conceptItems/create',
    async (data, { rejectWithValue }) => {
        try {

            const headers = {
                "Accept": "application/json",
                "Content-Type": "application/json",
            };

            const response = await axios.post('api/conceptitems/PostConceptItem', JSON.stringify(data),
                { headers })

            return response.data;
        } catch (err) {
            console.log("err: " + JSON.stringify(err.message))
            return rejectWithValue([], err);
        }
    })

export const updateConcept = createAsyncThunk('conceptItems/update',
    async (data, { rejectWithValue }) => {
        try {
            const headers = {
                "Accept": "application/json",
                "Content-Type": "application/json",
            };
            const response = await axios.put('api/ConceptItems/PutConceptItem/' + data.id, JSON.stringify(data),
                { headers })

            return response.data;
        } catch (err) {
            console.log("err: " + JSON.stringify(err.message))
            return rejectWithValue([], err);
        }
    })

    export const publishConcept = createAsyncThunk('conceptItems/publishConcept',
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.get('api/ConceptItems/PublishConcept/' + id)
            return response.data;
        } catch (err) {
            console.log("err: " + JSON.stringify(err.message))
            return rejectWithValue([], err);
        }      
    })

export const conceptsSlice = createSlice({
    name: "concepts",
    initialState,
    reducers: {
        getConcepts: (state, action) => {
            state.concepts = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchUnpublishedConcepts.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchUnpublishedConcepts.fulfilled, (state, { payload }) => {
                state.unpublishedConcepts = payload
                state.status = 'succeeded'

            })
            .addCase(fetchUnpublishedConcepts.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
                      
    }
})
export const getUnpublishedConcepts = (state) => state.concepts.unpublishedConcepts;
export const getConceptsStatus = (state) => state.concepts.status;
export const getConceptsError = (state) => state.concepts.error;
export const { setConcepts } = conceptsSlice.actions

export default conceptsSlice.reducer