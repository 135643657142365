import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { BRANDS_URL_PREFIX } from "../../../components/shared/Constants";

import {
    useGridApiRef,
    DataGridPro,
    GridToolbarContainer,
    GridToolbarExport,
    GridActionsCellItem,
} from '@mui/x-data-grid-pro';
import {
    randomId,
} from '@mui/x-data-grid-generator';
import ConfirmDialog, { confirmDialog } from '../../shared/ConfirmDialog';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { XDATA_GRID_PRO_LICENSE } from '../../shared/Constants'

LicenseInfo.setLicenseKey(
    XDATA_GRID_PRO_LICENSE
)

function EditToolbar(props) {
    const { apiRef } = props;

    const handleAddNewClick = () => {
        const id = randomId();
        apiRef.current.updateRows([{ id, inUseFlag: false, isNew: true }]);
        apiRef.current.setRowMode(id, 'edit');
        // Wait for the grid to render with the new row
        setTimeout(() => {
            apiRef.current.scrollToIndexes({
                rowIndex: apiRef.current.getRowsCount() - 1,
            });

            apiRef.current.setCellFocus(id, 'brandLogo');
        });
    };

    return (
        <GridToolbarContainer>
             <GridToolbarExport />
            <Button color="primary" startIcon={<AddIcon />} onClick={handleAddNewClick}>
                Add record
            </Button>
           
        </GridToolbarContainer>
    );
}

EditToolbar.propTypes = {
    apiRef: PropTypes.shape({
        current: PropTypes.object.isRequired,
    }).isRequired,
};

export default function BrandsManager() {
    const [snackbar, setSnackbar] = React.useState(null);
    const handleCloseSnackbar = () => setSnackbar(null);
    const [rows, setRows] = useState([]);
    const apiRef = useGridApiRef();

    useEffect(() => {
        fetch("api/brands/GetBrandsWithInUseFlag")
            .then((data) => data.json())
          //  .then((data) => data.sort((a, b) => (a.brandName > b.brandName) ? 1 : -1)  )
            .then((data) =>setRows(data))
    }, [])

    const handleDeleteConfirmed = (id) => {
      
        fetch('api/brands/' + id,
            {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },                    
            }).then((response) => {
                if (!response.ok) {                   
                    setSnackbar({ children: 'Error while saving record. See log for details.', severity: 'error' });
                } else {
                    apiRef.current.updateRows([{ id, _action: 'delete' }]);
                    setSnackbar({ children: 'Record successfully deleted', severity: 'success' });
                }
            }).catch(error => {
                console.log(error);               
                setSnackbar({ children: 'Error while saving record. See log for details.', severity: 'error' });
            });
        
    };

    const handleRowEditCommit = React.useCallback(
        async (id) => {
            const model = apiRef.current.getEditRowsModel(); // This object contains all rows that are being edited
            const newRow = model[id]; // The data that will be committed

            const name = newRow.brandName.value;
            const logo = newRow.brandLogo.value === undefined ? '' : newRow.brandLogo.value;
            const oldRow = apiRef.current.getRow(id);

            if (!oldRow.hasOwnProperty('isNew')) {//update                
                try {
                    const res = await fetch('api/brands/' + id, {
                        method: "PUT",
                        headers: {
                            'Accept': 'application/json',
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ 'id': id, 'BrandName': name, 'BrandLogo': logo })
                        });
                        if (!res.ok) {
                        apiRef.current.updateRows([oldRow]);                            
                        const message = `An error has occured: ${res.status} - ${res.statusText}`;
                        setSnackbar({ children: message, severity: 'error' });
                        
                        } else {
                        apiRef.current.updateRows([{   
                            id: id,
                            brandName: name,   
                            brandLogo: logo,                                                 
                        }])
                        setSnackbar({ children: 'Record successfully saved', severity: 'success' });
                        }
                        
                    } catch (error) { 
                        console.log(error);
                        apiRef.current.updateRows([oldRow]);
                        setSnackbar({ children: 'Error while saving record. See log for details.', severity: 'error' });
                    }
                } else { //new record
                   try {
                        const res = await fetch('api/brands/', {
                            method: "POST",
                            headers: {
                              'Accept': 'application/json',
                              "Content-Type": "application/json",
                            
                            },
                            body: JSON.stringify({ 'BrandName': name, 'BrandLogo': logo }),
                          });
                          if (!res.ok) {
                            const message = `An error has occured: ${res.status} - ${res.statusText}`;
                            setSnackbar({ children: message, severity: 'error' });
                          } else {
                            const data = await res.json();
                            const result = {
                                status: res.status + "-" + res.statusText,
                                headers: {
                                "Content-Type": res.headers.get("Content-Type"),
                                "Content-Length": res.headers.get("Content-Length"),
                                },
                                data: data,
                            };  
                       
                            apiRef.current.updateRows([{ id, _action: 'delete' }]);                  
                            apiRef.current.updateRows([{ 
                                id: result.data.id, 
                                brandName: result.data.brandName,  
                                brandLogo: result.data.brandLogo,              
                                inUseFlag: false,
                            }])
                            setSnackbar({ children: 'Record successfully saved', severity: 'success' });
                          }                        
                        
                        } catch (error) { 
                            console.log(error);
                            apiRef.current.updateRows([oldRow]);
                            setSnackbar({ children: 'Error while saving record. See log for details.', severity: 'error' });
                        }
            
            }
        },
        [apiRef],
    );

    const columns = [
        {
            field: 'brandLogo',
            headerName: 'Logo',
            width: 300,
            editable: true,
           // disableColumnMenu: true,
            renderCell: (params) => 
            <>
            <img 
            src={params.value !== '' && params.value !== undefined ? BRANDS_URL_PREFIX + params.value : "/images/blank.png"}           
            className="small-logo" 
            />{"  "}
           <span>{params.value !== '' && params.value !== undefined ?  params.value : ""}</span>
           </>
        },
        {
            field: 'brandName',
            headerName: 'Brand Name',
            width: 200,
            editable: true,
            // preProcessEditCellProps: (params) => {
            //     const hasError = params.props.value.length < 3;
            //     return { ...params.props, error: hasError };
            // },
        },
        
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Delete',
            width: 100,
            cellClassName: 'actions',
            editable: false,
            getActions: ({ id }) => {
                const canDelete = apiRef.current.getRow(id).inUseFlag == false;    
                 
                if (canDelete) {
                    return [
                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label="Delete"
                            onClick={() =>
                                confirmDialog("Are you sure you want to delete this record?",
                                    handleDeleteConfirmed.bind(
                                        this,
                                        id,)                                                                  
                                )
                            }
                            color="warning"
                        />,
                    ];
                }
                return [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        color="formfield"
                    />,
                ];
            },
        },
    ];

    return (
        <Box
            sx={{
                width: '100%',
               
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                    color: '#000000',
                    fontWeight: 600,
                    fontSize: '14px'
                },
                '& .MuiDataGrid-cell--editing': {
                    '& .MuiInputBase-root': {
                        height: '100%',
                    },
                },
                '& .Mui-error': {
                    bgcolor: (theme) =>
                        `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0 : 0.1})`,
                    color: (theme) => (theme.palette.mode === 'dark' ? '#ff4343' : '#750f0f'),
                },
            }}
        >
            <ConfirmDialog />
            <DataGridPro
                sx={{
                   height: 600,
                }}
                rows={rows}
                columns={columns}
                apiRef={apiRef}
                editMode="row"
                onRowEditCommit={handleRowEditCommit}
                disableColumnPinning={true}
                hideFooter={true}
                hideFooterPagination={true}
                hideFooterRowCount={true}
                hideFooterSelectedRowCount={true}  
            //    initialState={{ pinnedColumns: { right: ['actions'] } }}
                components={{
                    Toolbar: EditToolbar,
                }}
                componentsProps={{
                    toolbar: { apiRef },                   
                }}
                loading={rows.length === 0}
                initialState={{
                    sorting: {
                      sortModel: [{ field: 'brandName', sort: 'asc' }],
                    },
                }}
            />
            {!!snackbar && (
                <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={1600}>
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        </Box>
    );
}
