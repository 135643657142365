
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'


const initialState = {
    presentations: [],
    presentationItems: [],
    status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
}

export const fetchUserPresentations = createAsyncThunk('presentations/fetchUserPresentations', async (data) => {
    try {
        const response = await axios.post('api/Presentations/Presentations', data, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            },
        })
        return [...response.data];
    } catch (err) {
        return err.message;
    }
})

export const fetchPresentationItems = createAsyncThunk('presentations/fetchPresentationItems', async (data) => {
    try {
        const response = await axios.post('api/Presentations/GetPresentationItems', data, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            },
        })
        //console.log("GetPresentationItems", JSON.stringify(response.data))
        //  response.data.sort((a, b) => (a.presentationName > b.presentationName) ? 1 : -1)      
        return response.data;
    } catch (err) {
        return err.message;
    }
})


export const createPresentation = createAsyncThunk('presentations/create',
    async (data, { rejectWithValue }) => {
        try {

            const headers = {
                "Accept": "application/json",
                "Content-Type": "application/json",
            };

            const response = await axios.post('api/Presentations/PostPresentation', JSON.stringify(data),
                { headers })

            return response.data;
        } catch (err) {
            console.log("err: " + JSON.stringify(err.message))
            return rejectWithValue([], err);
        }
    })

export const updatePresentationMenuItems = createAsyncThunk('presentations/updateMenuItems',
    async (data, { rejectWithValue }) => {
        try {

            const headers = {
                "Accept": "application/json",
                "Content-Type": "application/json",
            };

            const response = await axios.post('api/Presentations/AttachItems', JSON.stringify(data),
                { headers })

            return response.data;
        } catch (err) {
            console.log("err: " + JSON.stringify(err.message))
            return rejectWithValue([], err);
        }
    })

export const updatePresentationItemOrder = createAsyncThunk('presentations/updateItemsOrder',
    async (data, { rejectWithValue }) => {
    //    console.log("UpdateConceptItemsOrder DATA::: " + JSON.stringify(data))
        try {
            const headers = {
                "Accept": "application/json",
                "Content-Type": "application/json",
            };

            const response = await axios.post('api/Presentations/UpdateConceptItemsOrder/update', JSON.stringify(data),
                { headers })

            return response.data;
        } catch (err) {
            console.log("err: " + JSON.stringify(err.message))
            return rejectWithValue([], err);
        }
    })

export const addMenuItems = createAsyncThunk('presentations/AddItem',
    async (data, { rejectWithValue }) => {
        try {

            const headers = {
                "Accept": "application/json",
                "Content-Type": "application/json",
            };

            const response = await axios.post('api/Presentations/AddItem', JSON.stringify(data),
                { headers })

            return response.data;
        } catch (err) {
            console.log("err: " + JSON.stringify(err.message))
            return rejectWithValue([], err);
        }
    })

const presentationsSlice = createSlice({
    name: "presentations",
    initialState,
    reducers: {
        setList: (state, { payload }) => {
            state.presentations = payload;
        },
        setItems: (state, { payload }) => {
            state.presentationItems = payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchUserPresentations.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchUserPresentations.fulfilled, (state, { payload }) => {
                state.status = 'succeeded'
                state.presentations = payload
            })
            .addCase(fetchUserPresentations.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(fetchPresentationItems.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchPresentationItems.fulfilled, (state, { payload }) => {
                state.status = 'succeeded'
                state.presentationItems = payload
            })
            .addCase(fetchPresentationItems.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export const selectUserPresentations = (state) => state.presentations.presentations;
export const selectPresentationItems = (state) => state.presentations.presentationItems;

export const { setList, setItems } = presentationsSlice.actions

export default presentationsSlice.reducer